import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AppRoutingModule } from '@app/app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { CalendarModule } from 'primeng/calendar';
import { CommonModule } from '@angular/common';
import { CoreModule } from '@core/core.module';
import { DataViewModule } from 'primeng/dataview';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { MessageModule } from 'primeng/message';
import { MessagesModule } from 'primeng/messages';
import { NgModule } from '@angular/core';
import { PaginatorModule } from 'primeng/paginator';
import { PesquisarEditalComponent } from '@pesquisarEdital/pesquisar-edital/pesquisarEdital.component';
import { SharedModule } from '@app/shared/shared.module';
import { SkeletonModule } from 'primeng/skeleton';
import { SkeletonPesquisarEditalComponent } from '@pesquisarEdital/skeleton-pesquisar-edital/skeleton-pesquisar-edital.component';

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		BrowserModule,
		BrowserAnimationsModule,
		AppRoutingModule,
		CoreModule,
		InputTextModule,
		DropdownModule,
		CalendarModule,
		DataViewModule,
		PaginatorModule,
		SharedModule,
		MessagesModule,
		MessageModule,
		SkeletonModule
	],
	declarations: [PesquisarEditalComponent, SkeletonPesquisarEditalComponent]
})
export class PesquisarEditalModule {}
