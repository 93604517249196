<div *ngIf="!loading && editais.length > 0">
	<p-table
		[value]="editais"
		[lazy]="true"
		(onPage)="paginar($event)"
		(onLazyLoad)="getItensPorPagina($event)"
		[loading]="loading"
		[first]="primeiroItemResultado"
		[totalRecords]="totalResultado!"
		dataKey="id"
		[rowHover]="true"
		responsiveLayout="stack"
		[autoLayout]="true"
		[paginator]="true"
		[rows]="itens"
		[rowsPerPageOptions]="[5,10,15,20]"
		sortField="nome"
		data-cy="resultado-pesquisa"
	>
		<ng-template pTemplate="caption">
			<div class="table-header">Total de editais: {{ totalResultado }}</div>
		</ng-template>
		<ng-template pTemplate="header">
			<tr>
				<th id="expandir" style="width: 3rem" class="display-sm-none" *ngIf="!pendentes"></th>
				<th id="cabecalho-edital" data-cy="cabecalho-edital">Edital</th>
				<th id="cabecalho-etapaAtual" data-cy="cabecalho-etapaAtual" *ngIf="!pendentes">Etapa atual</th>
				<th id="cabecalho-prazo" data-cy="cabecalho-prazo" class="text-center" *ngIf="pendentes">Prazo para aprovação</th>
				<th id="cabecalho-situacao" data-cy="cabecalho-situacao">Situação</th>
				<th id="cabecalho-candidatos" data-cy="cabecalho-candidatos" class="text-center" *ngIf="!pendentes">
					Candidatos inscritos
				</th>
				<th id="cabecalho-atualizacao" data-cy="cabecalho-atualizacao" class="text-center">Última atualização</th>
			</tr>
		</ng-template>
		<ng-template pTemplate="body" let-edital let-expanded="expanded">
			<tr>
				<td headers="expandir" class="display-sm-none" *ngIf="!pendentes">
					<p-button
						styleClass="circle"
						[icon]="expanded ? 'fas fa-chevron-up' : 'fas fa-chevron-down'"
						title="Mais informações do edital"
						alt="Mais informações do edital"
						[pRowToggler]="edital"
						data-cy="expandir-candidato-oportunidade"
					></p-button>
				</td>
				<td headers="cabecalho-edital" data-cy="campo-edital">
					<span class="p-column-title">Edital:</span>
					<a [routerLink]="['/edital', edital.id]" routerLinkActive="router-link-active" data-cy="situacao-edital">
						{{ edital.id }} - Edital {{edital.numeroEdital! | numeroEdital}} - {{ edital.nomeOrgao }}</a
					>
				</td>
				<td headers="cabecalho-etapaAtual" data-cy="campo-etapaAtual" *ngIf="!pendentes">
					<span class="p-column-title">Etapa atual:</span>
					<div *ngIf="edital.etapas.length > 0; else semEtapaAtual">
						{{edital.etapas[0].tipoEtapaEdital.descricao | imprimirDash}}
						<div>Data final: {{edital.etapas[0].dataFimEtapa}}</div>
					</div>
					<ng-template #semEtapaAtual>-</ng-template>
				</td>
				<td headers="cabecalho-prazo" data-cy="campo-prazo" class="text-center" *ngIf="pendentes">
					<span class="p-column-title">Prazo para aprovação:</span>
					<span *ngIf="edital.etapas.length > 0 else semPrazo">{{ edital.etapas[0].dataInicioEtapa | dateAgo }}</span>
					<ng-template #semPrazo>2 dias</ng-template>
				</td>
				<td headers="cabecalho-situacao" data-cy="campo-situacao">
					<span class="p-column-title">Situação:</span>
					<div class="br-tag text {{edital.situacao.codigo}}">
						<span class="text-base text-down-01">{{edital.situacao.descricao}}</span>
					</div>
				</td>
				<td headers="cabecalho-candidatos" data-cy="campo-candidatos" *ngIf="!pendentes" class="text-center">
					<span class="p-column-title">Candidatos inscritos:</span>
					{{ edital.candidatosInscritos | imprimirDash }}
				</td>
				<td headers="cabecalho-atualizacao" data-cy="campo-atualizacao" class="text-center">
					<span class="p-column-title">Última atualização:</span>
					{{ edital.dataHoraAtualizacao | dateAgo }}
				</td>
			</tr>
		</ng-template>
		<ng-template pTemplate="rowexpansion" let-edital *ngIf="!pendentes">
			<tr>
				<td colspan="6">
					<strong>Oportunidades: </strong>
					<span *ngFor="let oportunidade of edital.oportunidades; last as last">
						{{ oportunidade.nomeOportunidade }}<span *ngIf="!last">, </span>
					</span>
					<span *ngIf="edital.oportunidades.length === 0">-</span>
				</td>
			</tr>
		</ng-template>
	</p-table>
</div>
<sgp-skeleton-card-editais *ngIf="loading"></sgp-skeleton-card-editais>
<div *ngIf="!loading && editais.length === 0" class="ml-4" data-cy="nao-existem-editais">Não há editais para exibição</div>
