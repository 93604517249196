<p data-cy="texto-explicativo">
	Revise os dados do edital abaixo. Caso alguma informação esteja incorreta, corrija na sessão correspondente. Quando tudo estiver pronto,
	clique em Solicitar Publicação quando ele estiver pronto para ser publicado.
</p>
<h6 class="font-primary-default" data-cy="titulo-dados-basicos">Dados básicos</h6>
<div *ngIf="edital$">
	<p-panel styleClass="mb-3">
		<sgp-item-label-descricao
			id="identificador-edital-revisao"
			label="Identificador do edital"
			[conteudo]="edital$.id"
		></sgp-item-label-descricao>
		<sgp-item-label-descricao
			id="numero-edital-revisao"
			label="Número do edital"
			[conteudo]="edital$.numeroEdital"
		></sgp-item-label-descricao>
		<sgp-item-label-descricao
			id="situacao-edital-revisao"
			label="Situação"
			[conteudo]="edital$.situacao?.descricao"
		></sgp-item-label-descricao>
		<sgp-item-label-descricao id="orgao-revisao" label="Órgão" [conteudo]="edital$.nomeOrgao"></sgp-item-label-descricao>
		<sgp-item-label-descricao
			id="preambulo-revisao"
			label="Preâmbulo"
			[conteudo]="edital$.preambulo"
			[renderizarHTML]="true"
		></sgp-item-label-descricao>
		<sgp-item-label-descricao
			id="sobre-unidade-revisao"
			label="Sobre a unidade"
			[conteudo]="edital$.sobreUnidade"
			[renderizarHTML]="true"
		></sgp-item-label-descricao>
		<sgp-item-label-descricao
			id="exigencias-revisao"
			label="Exigências legais para abertura da oportunidade"
			[conteudo]="edital$.exigenciaLegal"
			[renderizarHTML]="true"
		></sgp-item-label-descricao>
		<sgp-item-label-descricao
			id="sobre-vagas-oportunidades-revisao"
			label="Sobre as vagas e oportunidades"
			[conteudo]="edital$.sobreVagasOportunidades"
			[renderizarHTML]="true"
		></sgp-item-label-descricao>
		<sgp-item-label-descricao
			id="email-revisao"
			label="Email"
			[conteudo]="edital$.emailContato"
			[obrigatorioPublicacao]="true"
		></sgp-item-label-descricao>
	</p-panel>

	<h6 class="font-primary-default" data-cy="titulo-oportunidades">Oportunidades</h6>
	<p-panel styleClass="mb-3">
		<div *ngIf="edital$.oportunidades?.length === 0">
			Nenhuma oportunidade cadastrada
			<sgp-message-feedback
				[show]="true"
				mensagem="O edital precisa de no mínimo uma oportunidade para ser publicado"
				tipo="info"
			></sgp-message-feedback>
		</div>
		<div class="mb-4" *ngFor="let oportunidade of edital$.oportunidades; let last = last">
			<sgp-item-label-descricao
				id="nome-oportunidade-revisao"
				label="Nome da oportunidade"
				[conteudo]="oportunidade.nomeOportunidade"
			></sgp-item-label-descricao>
			<sgp-item-label-descricao
				id="unidade-oportunidade-revisao"
				label="Unidade"
				[conteudo]="oportunidade.nomeUorg"
			></sgp-item-label-descricao>
			<sgp-item-label-descricao
				id="localidade-oportunidade-revisao"
				label="Localidade"
				[conteudo]="oportunidade.nomeLocalidade"
			></sgp-item-label-descricao>

			<div class="row mb-2">
				<div class="col-sm-2">
					<label>Descrição resumida:</label>
				</div>
				<div class="col-sm-10">
					<div *ngIf="oportunidade.descricaoResumida; else descricaoVazia" [innerHtml]="oportunidade.descricaoResumida"></div>
					<ng-template #descricaoVazia>-</ng-template>
				</div>
			</div>
			<div class="row mb-2">
				<div class="col-sm-2">
					<label>Atividades a serem executadas:</label>
				</div>
				<div class="col-sm-10">
					<div *ngIf="oportunidade.atividades; else atividadesVazia" [innerHtml]="oportunidade.atividades"></div>
					<ng-template #atividadesVazia>-</ng-template>
				</div>
			</div>

			<sgp-item-label-descricao
				id="movimentacao-oportunidade-revisao"
				label="Tipo de movimentação"
				[conteudo]="oportunidade.tipoMovimentacao.descricao"
				*ngIf="oportunidade.tipoMovimentacao"
			></sgp-item-label-descricao>
			<sgp-item-label-descricao
				id="programa-oportunidade-revisao"
				label="Programa de gestão"
				[conteudo]="oportunidade.programaGestao.descricao"
			></sgp-item-label-descricao>
			<sgp-item-label-descricao
				id="jornada-oportunidade-revisao"
				label="Jornada semanal"
				[conteudo]="oportunidade.tipoJornada.descricao"
			></sgp-item-label-descricao>
			<sgp-item-label-descricao
				id="gratificacao-oportunidade-revisao"
				label="Incentivo/gratificação"
				[conteudo]="oportunidade.incentivo.descricao?.toUpperCase()"
			></sgp-item-label-descricao>
			<sgp-item-label-descricao
				id="vagas-oportunidade-revisao"
				label="Quantidade de vagas para esta oportunidade"
				[conteudo]="oportunidade.numeroVagas?.toString()"
			></sgp-item-label-descricao>

			<p class="text-semi-bold my-3 text-up-01">Requisitos da oportunidade</p>

			<!-- Requisitos -->
			<div class="mb-2">
				<sgp-message-feedback
					[show]="oportunidade | requisitosObrigatorios"
					mensagem="A oportunidade deve possuir no mínimo um requisito distinto de 'Outros requisitos'."
					tipo="info"
				></sgp-message-feedback>
			</div>

			<div class="row mb-2" *ngIf="oportunidade.formacoes && oportunidade.formacoes.length > 0">
				<div class="col-sm-2">
					<label>Formação Acadêmica:</label>
				</div>
				<div class="col-sm-10">
					<div class="text-capitalize" *ngFor="let formacao of oportunidade.formacoes">
						{{formacao.nomeNivelCurso?.toLowerCase()}} - {{formacao.nomeFormacao?.toLowerCase()}}
						<span *ngIf="formacao.obrigatorio">(Obrigatório)</span>
					</div>
				</div>
			</div>

			<div class="row mb-2" *ngIf="oportunidade.nomeMunicipioResidencia">
				<div class="col-sm-2">
					<label>Residência:</label>
				</div>
				<div class="col-sm-10">{{ oportunidade.nomeMunicipioResidencia }}</div>
			</div>

			<div class="row mb-2" *ngIf="oportunidade.nomeOrgaoLotacao">
				<div class="col-sm-2">
					<label>Órgão de lotação:</label>
				</div>
				<div class="col-sm-10">{{ oportunidade.nomeOrgaoLotacao }} <span>(Obrigatório)</span></div>
			</div>

			<div class="row mb-2" *ngIf="oportunidade.nomeOrgaoExercicio">
				<div class="col-sm-2">
					<label>Órgão de exercício:</label>
				</div>
				<div class="col-sm-10">{{ oportunidade.nomeOrgaoExercicio }} <span>(Obrigatório)</span></div>
			</div>

			<div class="row mb-2" *ngIf="oportunidade.experiencias && oportunidade.experiencias.length > 0">
				<div class="col-sm-2">
					<label>Experiências Profissionais:</label>
				</div>
				<div class="col-sm-10">
					<div *ngFor="let experiencia of oportunidade.experiencias">
						<div>
							Experiência: {{ experiencia.nomeExperiencia }}
							<span *ngIf="experiencia.servicoPublico"> - No serviço público</span>
							<span *ngIf="experiencia.obrigatorio"> - Obrigatório</span>
						</div>
						<div>Meses de experiência: {{experiencia.qtdMinimaMesesExperiencia}}</div>
					</div>
				</div>
			</div>

			<div class="row mb-2" *ngIf="oportunidade.areasAtuacao && oportunidade.areasAtuacao.length > 0">
				<div class="col-sm-2">
					<label>Áreas de Atuação:</label>
				</div>
				<div class="col-sm-10">
					<div *ngFor="let area of oportunidade.areasAtuacao">
						<div>{{area.nome?.toUpperCase()}} <span *ngIf="area.obrigatorio">(Obrigatório)</span></div>
					</div>
				</div>
			</div>

			<div class="row mb-2" *ngIf="oportunidade.areasConhecimento && oportunidade.areasConhecimento.length > 0">
				<div class="col-sm-2">
					<label>Conhecimentos técnicos:</label>
				</div>
				<div class="col-sm-10">
					<div *ngFor="let area of oportunidade.areasConhecimento">
						<div>{{area.nome?.toUpperCase()}} <span *ngIf="area.obrigatorio">(Obrigatório)</span></div>
					</div>
				</div>
			</div>

			<div class="row mb-2" *ngIf="oportunidade.capacitacoes && oportunidade.capacitacoes.length > 0">
				<div class="col-sm-2">
					<label>Capacitações:</label>
				</div>
				<div class="col-sm-10">
					<div *ngFor="let capacitacao of oportunidade.capacitacoes">
						<div>{{capacitacao.nomeCapacitacao}} <span *ngIf="capacitacao.obrigatorio">(Obrigatório)</span></div>
					</div>
				</div>
			</div>

			<div class="row mb-2" *ngIf="oportunidade.certificacoes && oportunidade.certificacoes.length > 0">
				<div class="col-sm-2">
					<label>Certificações:</label>
				</div>
				<div class="col-sm-10">
					<div *ngFor="let certificacao of oportunidade.certificacoes">
						<div>{{certificacao.nomeCertificacao}} <span *ngIf="certificacao.obrigatorio">(Obrigatório)</span></div>
					</div>
				</div>
			</div>

			<div class="row mb-2" *ngIf="oportunidade.competencias && oportunidade.competencias.length > 0">
				<div class="col-sm-2">
					<label>Softskills:</label>
				</div>
				<div class="col-sm-10">
					<div *ngFor="let competencia of oportunidade.competencias">
						<div>{{competencia.nome?.toUpperCase()}} <span *ngIf="competencia.obrigatorio">(Obrigatório)</span></div>
					</div>
				</div>
			</div>

			<div class="row mb-2" *ngIf="oportunidade.vinculos && oportunidade.vinculos.length > 0">
				<div class="col-sm-2">
					<label>Vínculos:</label>
				</div>
				<div class="col-sm-10">
					<div *ngFor="let vinculo of oportunidade.vinculos">
						<div>
							{{vinculo.vinculo!.nome?.toUpperCase()}}
							<span *ngIf="vinculo.excetoEstagioProbatorio">: Exceto em estágio probatório</span>
							<span *ngIf="vinculo.obrigatorio">(Obrigatório)</span>
						</div>
					</div>
				</div>
			</div>

			<div class="row mb-2">
				<div class="col-sm-2">
					<label>Idiomas:</label>
				</div>
				<div class="col-sm-10">
					<div *ngFor="let idioma of oportunidade.idiomas">
						<div>{{idioma.nome?.toUpperCase()}} <span *ngIf="idioma.obrigatorio"> (Obrigatório)</span></div>
						<div>- Nível de compreensão: {{idioma.nivelCompreensao | idioma}}</div>
						<div>- Nível de escrita: {{idioma.nivelEscrita | idioma}}</div>
						<div>- Nível de leitura: {{idioma.nivelLeitura | idioma}}</div>
						<div>
							<span *ngIf="idioma?.idIdioma === 39; else naoLibras">- Nível de expressão</span>
							<ng-template #naoLibras>- Nível de fala</ng-template>
							{{idioma.nivelFala | idioma}}
						</div>
					</div>
				</div>
			</div>

			<div class="row mb-2" *ngIf="oportunidade.outrosRequisitos">
				<div class="col-sm-2">
					<label>Outros Requisitos:</label>
				</div>
				<div class="col-sm-10">{{oportunidade.outrosRequisitos}}</div>
			</div>

			<span class="br-divider my-3" *ngIf="!last"></span>
		</div>
	</p-panel>

	<h6 class="font-primary-default" data-cy="titulo-etapas">Etapas</h6>
	<p-panel styleClass="mb-3">
		<div *ngIf="edital$.etapas?.length === 0">
			Nenhuma etapa cadastrada
			<sgp-message-feedback
				[show]="true"
				mensagem="O edital precisa de no mínimo uma etapa para ser publicado"
				tipo="info"
			></sgp-message-feedback>
		</div>
		<div class="mb-4" *ngFor="let etapa of edital$.etapas; let last = last">
			<sgp-item-label-descricao
				id="nome-etapa-revisao"
				label="Etapa"
				[conteudo]="etapa.tipoEtapaEdital?.descricao"
			></sgp-item-label-descricao>
			<sgp-item-label-descricao
				id="descricao-etapa-revisao"
				label="Descrição"
				[conteudo]="etapa.descricaoEtapa"
			></sgp-item-label-descricao>
			<div class="row mb-2">
				<div class="col-sm-2">
					<label for="id">Data inicial:</label>
				</div>
				<div class="col-sm-10">
					<span>{{etapa.dataInicioEtapa}}</span>
					<span *ngIf="etapa.dataInicioEtapa === null">-</span>
					<sgp-message-feedback
						[show]="etapa.dataInicioEtapa === null"
						mensagem="Preenchimento obrigatório para publicação do edital"
						tipo="info"
					></sgp-message-feedback>
				</div>
			</div>
			<div class="row mb-2">
				<div class="col-sm-2">
					<label for="id">Data final:</label>
				</div>
				<div class="col-sm-10">
					<span>{{etapa.dataFimEtapa}}</span>
					<span *ngIf="etapa.dataFimEtapa === null">-</span>
					<sgp-message-feedback
						[show]="etapa.dataFimEtapa === null"
						mensagem="Preenchimento obrigatório para publicação do edital"
						tipo="info"
					></sgp-message-feedback>
				</div>
			</div>
			<sgp-item-label-descricao
				id="orientacoes-etapa-revisao"
				label="Orientações"
				[conteudo]="etapa.orientacoesEtapa"
				[renderizarHTML]="true"
			></sgp-item-label-descricao>
			<sgp-item-label-descricao
				id="candidatura-etapa-revisao"
				label="Candidatura"
				[conteudo]="etapa.informacaoCandidatura"
				[renderizarHTML]="true"
			></sgp-item-label-descricao>
			<div class="row mb-2">
				<div class="col-sm-2">
					<label for="vagas-oportunidade-revisao">Permite Enviar Documentos:</label>
				</div>
				<div class="col-sm-10">
					<span id="vagas-oportunidade-revisao">{{etapa.permiteEnvioDocumentos | imprimirSimNao}}</span>
				</div>
			</div>
			<div class="row mb-2" *ngIf="etapa.permiteEnvioDocumentos">
				<div class="col-sm-2">
					<label for="vagas-oportunidade-revisao">Tipo de Documentos:</label>
				</div>
				<div class="col-sm-10">
					<span id="vagas-oportunidade-revisao">
						<span *ngIf="etapa.tiposDocumento?.length === 0; else exibirTiposDocumentos">-</span>
						<ng-template #exibirTiposDocumentos>
							<ul>
								<li *ngFor="let tipo of etapa?.tiposDocumento" class="text-capitalize">
									<span>{{tipo.nome?.toLowerCase()}} <span *ngIf="!tipo.ativo">(Inativo)</span></span>
								</li>
							</ul>
						</ng-template>
					</span>
					<sgp-message-feedback
						[show]="etapa?.tiposDocumento?.length === 0"
						mensagem="Preenchimento obrigatório para publicação do edital"
						tipo="info"
						*ngIf="etapa.permiteEnvioDocumentos && etapa.tiposDocumento?.length === 0"
					></sgp-message-feedback>
				</div>
			</div>
			<span class="br-divider my-3" *ngIf="!last"></span>
		</div>
	</p-panel>

	<h6 class="font-primary-default" data-cy="titulo-anexos">Anexos</h6>
	<p-panel styleClass="mb-3">
		<div *ngIf="anexos?.length === 0">Nenhum arquivo anexado</div>
		<div class="mb-4" *ngFor="let anexo of anexos; let last = last">
			<a role="button" (click)="download(anexo.idAnexo!)" class="cursor-pointer"
				><i class="fas fa-file-pdf"></i> {{anexo.descricaoAnexo}} - {{anexo.tipoAnexo?.descricao}}</a
			>
		</div>
	</p-panel>

	<div class="row mt-4 mb-4">
		<div class="col-sm-12 text-right">
			<span *possuiPerfil="perfisEnum.GESTOR">
				<button
					class="br-button primary mt-3 mt-sm-0 ml-sm-3"
					(click)="exibirModalSolicitarPublicacao = true"
					type="button"
					pButton
					data-cy="botao-solicitar-publicacao"
					[disabled]="desabilitarSolicitarPublicacao"
					[attr.title]="mensagemBotaoSolicitarPublicacao"
					*ngIf="edital$.situacao | podeSerSituacao: situacoesEnum.RAS"
				>
					Solicitar publicação
				</button>
			</span>

			<span *possuiPerfil="[perfisEnum.GESTOR, perfisEnum.GESTOR_REFORMA]">
				<button
					class="br-button primary mt-3 mt-sm-0 ml-sm-3"
					type="button"
					(click)="exibirModalSolicitarRetificacao = true"
					data-cy="botao-retificar-edital"
					*ngIf="edital$.situacao | podeSerSituacao: situacoesEnum.RET"
				>
					Preparar texto da retificação
				</button>
			</span>
		</div>
	</div>

	<sgp-solicitar-acao
		id="SolicitarPublicacao"
		titulo="Solicitar publicação de edital"
		[tipoAcao]="acoesEnum.OPO_SOLICITAR_PUBLICACAO_EDITAL"
		[edital]="edital$"
		labelBotaoAcao="Solicitar"
		[showModal]="exibirModalSolicitarPublicacao"
		(fecharModal)="fecharModal($event)"
		(editalAtualizado)="atualizarEdital($event)"
		*ngIf="exibirModalSolicitarPublicacao"
	></sgp-solicitar-acao>

	<sgp-solicitar-acao
		id="SolicitarRetificacao"
		titulo="Solicitar publicação de retificação de edital"
		[tipoAcao]="acoesEnum.OPO_SOLICITAR_PUBLICACAO_RETIFICACAO"
		[edital]="edital$"
		labelBotaoAcao="Solicitar"
		[showModal]="exibirModalSolicitarRetificacao"
		(fecharModal)="fecharModal($event)"
		(editalAtualizado)="atualizarEdital($event)"
		*ngIf="exibirModalSolicitarRetificacao"
	></sgp-solicitar-acao>
</div>
