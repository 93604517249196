import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AppRoutingModule } from '@app/app-routing.module';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { ButtonModule } from 'primeng/button';
import { ChipsModule } from 'primeng/chips';
import { CommonModule } from '@angular/common';
import { CoreModule } from '@core/core.module';
import { DataViewModule } from 'primeng/dataview';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { MascaraCpfPipe } from '../shared/pipes/mascara-cpf.pipe';
import { MessageModule } from 'primeng/message';
import { MessagesModule } from 'primeng/messages';
import { NgModule } from '@angular/core';
import { SharedModule } from '@app/shared/shared.module';
import { SolicitarBaseCurriculosComponent } from './solicitar-base-curriculos/solicitar-base-curriculos.component';
import { TableModule } from 'primeng/table';
import { TagModule } from 'primeng/tag';
import { ToastModule } from 'primeng/toast';
import { TooltipModule } from 'primeng/tooltip';

@NgModule({
	declarations: [SolicitarBaseCurriculosComponent],
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		AppRoutingModule,
		CoreModule,
		SharedModule,
		BrowserModule,
		BrowserAnimationsModule,
		AutoCompleteModule,
		ButtonModule,
		DataViewModule,
		DialogModule,
		DropdownModule,
		MessageModule,
		MessagesModule,
		TagModule,
		ToastModule,
		ChipsModule,
		TableModule,
		TooltipModule,
		MascaraCpfPipe
	]
})
export class GestaoDadosModule {}
