import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AvatarModule } from 'primeng/avatar';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ButtonModule } from 'primeng/button';
import { CommonModule } from '@angular/common';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { CoreModule } from '@core/core.module';
import { DataEtapaPipe } from '@core/pipes/dataEtapa.pipe';
import { DetalharAnexosCandidatoComponent } from './detalhar-anexos-candidato/detalhar-anexos-candidato.component';
import { DetalharCandidatoComponent } from './detalhar-candidato/detalhar-candidato.component';
import { DetalharMensagensCandidatoComponent } from './detalhar-mensagens-candidato/detalhar-mensagens-candidato.component';
import { DialogModule } from 'primeng/dialog';
import { EditorModule } from 'primeng/editor';
import { EnviarNotificacaoComponent } from './enviar-notificacao/enviar-notificacao.component';
import { MenubarModule } from 'primeng/menubar';
import { MessageModule } from 'primeng/message';
import { MessagesModule } from 'primeng/messages';
import { NumeroEditalPipe } from '@core/pipes/numeroEdital.pipe';
import { PanelModule } from 'primeng/panel';
import { RatingModule } from 'primeng/rating';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { SharedModule } from '@app/shared/shared.module';
import { SigepeSegurancaModule } from '@sigepe/ngx-sigepe-seguranca';
import { SkeletonModule } from 'primeng/skeleton';
import { SkeletonVisualizarEditalComponent } from './skeleton-visualizar-edital/skeleton-visualizar-edital.component';
import { TabViewModule } from 'primeng/tabview';
import { TableModule } from 'primeng/table';
import { TimelineModule } from 'primeng/timeline';
import { ToastModule } from 'primeng/toast';
import { VisualizarAnexosComponent } from '@visualizarEdital/visualizar-anexos/visualizar-anexos.component';
import { VisualizarCandidatosComponent } from '@visualizarEdital/visualizar-candidatos/visualizar-candidatos.component';
import { VisualizarDadosBasicosComponent } from '@visualizarEdital/visualizar-dados-basicos/visualizar-dados-basicos.component';
import { VisualizarDadosEditalComponent } from '@visualizarEdital/visualizar-dados-edital/visualizar-dados-edital.component';
import { VisualizarEditalComponent } from '@visualizarEdital/visualizar-edital/visualizar-edital.component';
import { VisualizarEtapasEditalComponent } from '@visualizarEdital/visualizar-etapas-edital/visualizar-etapas-edital.component';
import { VisualizarHistoricoEditalComponent } from '@visualizarEdital/visualizar-historico-edital/visualizar-historico-edital.component';
import { VisualizarMensagemAlertaComponent } from '@visualizarEdital/visualizar-mensagem-alerta/visualizar-mensagem-alerta.component';
import { VisualizarOportunidadeComponent } from '@visualizarEdital/visualizar-oportunidade/visualizar-oportunidade.component';
import { environment } from '@environments/environment';

@NgModule({
	declarations: [
		EnviarNotificacaoComponent,
		VisualizarEditalComponent,
		VisualizarAnexosComponent,
		VisualizarDadosEditalComponent,
		VisualizarCandidatosComponent,
		VisualizarDadosBasicosComponent,
		VisualizarEtapasEditalComponent,
		VisualizarHistoricoEditalComponent,
		VisualizarMensagemAlertaComponent,
		VisualizarOportunidadeComponent,
		SkeletonVisualizarEditalComponent,
		DetalharCandidatoComponent,
		DetalharAnexosCandidatoComponent,
		DetalharMensagensCandidatoComponent
	],
	imports: [
		BrowserAnimationsModule,
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		CoreModule,
		SharedModule,
		AvatarModule,
		ButtonModule,
		ConfirmDialogModule,
		DialogModule,
		EditorModule,
		MenubarModule,
		MessagesModule,
		MessageModule,
		PanelModule,
		RatingModule,
		TabViewModule,
		TableModule,
		TimelineModule,
		ToastModule,
		ScrollPanelModule,
		SkeletonModule,
		SigepeSegurancaModule.forRoot(environment.SEGURANCA_CONFIG)
	],
	providers: [DataEtapaPipe, NumeroEditalPipe],
	exports: [],
	schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class VisualizarEditalModule {}
