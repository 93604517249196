import { Component, Input } from '@angular/core';

@Component({
	selector: 'sgp-mini-card',
	templateUrl: './mini-card.component.html',
	styleUrls: ['./mini-card.component.scss']
})
export class MiniCardComponent {
	@Input() cor!: string;
	@Input() icone!: string;
	@Input() quantidade?: string;
	@Input() descricao!: string;
	@Input() ajuda = false;
	@Input() textoAjuda?: string;
}
