<div #mensagemErro>
	<p-messages [(value)]="msgErro" [enableService]="false" [closable]="false"></p-messages>
</div>
<h5>Dados básicos da oportunidade</h5>

<div class="row mt-2">
	<div class="col-sm-6">
		<div class="br-input" [ngClass]="{'danger': erroNomeOportunidade}">
			<label for="nome-oportunidade" #labelNomeOportunidade>Nome da oportunidade</label>
			<input
				pInputText
				type="text"
				id="nome-oportunidade"
				[(ngModel)]="oportunidade$.nomeOportunidade"
				placeholder="Escreva a descrição da oportunidade"
				data-cy="campo-nome-oportunidade"
				maxlength="100"
			/>
			<sgp-message-feedback
				[show]="erroNomeOportunidade"
				mensagem="Campo obrigatório"
				data-cy="erro-nome-oportunidade"
			></sgp-message-feedback>
		</div>
	</div>
</div>

<div class="row mt-4">
	<div class="col-sm-1">
		<label for="unidade-oportunidade" #labelUnidade>Unidade</label>
	</div>
	<div class="col-sm-9">
		<span>{{ nomeUnidadePadrao }}</span>
		<div class="mt-2">
			<p-button icon="fas fa-search" label="Buscar outra unidade" (click)="exibirModalBuscaUnidade = true"></p-button>
		</div>

		<sgp-busca-unidade
			[showModal]="exibirModalBuscaUnidade"
			[codigoOrgao]="codigoOrgao"
			(atualizarUnidade)="atualizarUnidade($event)"
			(atualizarShowModal)="atualizarExibirModalBuscaUnidade($event)"
		></sgp-busca-unidade>
	</div>
</div>

<div class="row mt-2">
	<div class="col-sm-4">
		<div class="br-input">
			<label for="localidade-oportunidade">Localidade (Opcional)</label>
			<p-autoComplete
				id="localidade-oportunidade"
				[(ngModel)]="oportunidade$.localidade"
				[suggestions]="filteredLocalidade"
				(completeMethod)="filterLocalidade($event)"
				autocomplete="off"
				field="name"
				[minLength]="1"
				[forceSelection]="true"
				[dropdown]="true"
				[showEmptyMessage]="true"
				emptyMessage="Nenhuma opção encontrada"
				data-cy="campo-localidade"
			>
			</p-autoComplete>
		</div>
	</div>
</div>

<div class="row mt-2">
	<div class="col-sm-10">
		<div class="br-input">
			<label for="descricao-oportunidade"
				>Descrição resumida (opcional)
				<i
					class="fas fa-question-circle text-blue-warm-vivid-70 cursor-pointer"
					title="Dicas de preenchimento"
					(click)="exibirDica = true"
				></i>
			</label>
			<p-editor
				id="descricao-oportunidade"
				[(ngModel)]="oportunidade$.descricaoResumida"
				[style]="{'height':'150px'}"
				data-cy="campo-descricao-resumida"
			>
				<ng-template pTemplate="header">
					<span class="ql-formats">
						<button type="button" class="ql-bold" aria-label="Negrito" title="Negrito"></button>
						<button type="button" class="ql-italic" aria-label="Itálico" title="Itálico"></button>
						<button type="button" class="ql-underline" aria-label="Sublinhado" title="Sublinhado"></button>
						<button type="button" class="ql-list" value="ordered" aria-label="Lista numerada" title="Lista numerada"></button>
						<button type="button" class="ql-list" value="bullet" aria-label="Lista com itens" title="Lista com itens"></button>
						<span class="ql-format-separator"></span>
						<button type="button" class="ql-clean" aria-label="Limpar formatação" title="Limpar formatação"></button>
					</span>
				</ng-template>
			</p-editor>
		</div>
	</div>
</div>

<div class="row mt-2">
	<div class="col-sm-10">
		<div class="br-input">
			<label for="atividades-oportunidade"
				>Atividades a serem executadas (opcional)
				<i
					class="fas fa-question-circle text-blue-warm-vivid-70 cursor-pointer"
					title="Dicas de preenchimento"
					(click)="exibirDica = true"
				></i>
			</label>
			<p-editor
				id="atividades-oportunidade"
				[(ngModel)]="oportunidade$.atividades"
				[style]="{'height':'150px'}"
				data-cy="campo-atividades"
			>
				<ng-template pTemplate="header">
					<span class="ql-formats">
						<button type="button" class="ql-bold" aria-label="Negrito" title="Negrito"></button>
						<button type="button" class="ql-italic" aria-label="Itálico" title="Itálico"></button>
						<button type="button" class="ql-underline" aria-label="Sublinhado" title="Sublinhado"></button>
						<button type="button" class="ql-list" value="ordered" aria-label="Lista numerada" title="Lista numerada"></button>
						<button type="button" class="ql-list" value="bullet" aria-label="Lista com itens" title="Lista com itens"></button>
						<span class="ql-format-separator"></span>
						<button type="button" class="ql-clean" aria-label="Limpar formatação" title="Limpar formatação"></button>
					</span>
				</ng-template>
			</p-editor>
		</div>
	</div>
</div>

<div class="row mt-2">
	<div class="col-sm-4">
		<div class="br-input" [ngClass]="{'danger': erroTipoMovimentacao}">
			<label for="movimentacao-oportunidade" #labelTipoMovimentacao>Tipo de movimentação</label>
			<p-autoComplete
				id="movimentacao-oportunidade"
				[(ngModel)]="oportunidade$.tipoMovimentacao"
				[showEmptyMessage]="true"
				[suggestions]="filteredTipoMovimentacao"
				(completeMethod)="filterTipoMovimentacao($event)"
				field="descricao"
				[minLength]="3"
				[size]="200"
				[dropdown]="true"
				[forceSelection]="true"
				emptyMessage="Nenhuma opção encontrada"
				data-cy="campo-movimentacao"
			></p-autoComplete>
			<sgp-message-feedback
				[show]="erroTipoMovimentacao"
				mensagem="Campo obrigatório"
				data-cy="erro-campo-movimentacao"
			></sgp-message-feedback>
		</div>
	</div>
</div>

<div class="row mt-2">
	<div class="col-sm-4">
		<div class="br-input" [ngClass]="{'danger': erroProgramaGestao}">
			<label for="programa-oportunidade" #labelProgramaGestao>Programa de gestão</label>
			<p-autoComplete
				id="programa-oportunidade"
				[(ngModel)]="oportunidade$.programaGestao"
				[showEmptyMessage]="true"
				[suggestions]="filteredProgramaGestao"
				(completeMethod)="filterProgramaGestao($event)"
				field="descricao"
				[minLength]="3"
				[size]="200"
				[dropdown]="true"
				[forceSelection]="true"
				emptyMessage="Nenhuma opção encontrada"
				data-cy="campo-programa-gestao"
			>
			</p-autoComplete>
			<sgp-message-feedback
				[show]="erroProgramaGestao"
				mensagem="Campo obrigatório"
				data-cy="erro-campo-programa-gestao"
			></sgp-message-feedback>
		</div>
	</div>
</div>

<div class="row mt-2">
	<div class="col-sm-4">
		<div class="br-input" [ngClass]="{'danger': erroTipoJornada}">
			<label for="jornada-oportunidade" #labelTipoJornada>Jornada semanal</label>
			<p-autoComplete
				id="jornada-oportunidade"
				[(ngModel)]="oportunidade$.tipoJornada"
				[showEmptyMessage]="true"
				[suggestions]="filteredTipoJornada"
				(completeMethod)="filterTipoJornada($event)"
				field="descricao"
				[minLength]="3"
				[size]="200"
				[dropdown]="true"
				[forceSelection]="true"
				emptyMessage="Nenhuma opção encontrada"
				data-cy="campo-jornada-semanal"
			></p-autoComplete>
			<sgp-message-feedback
				[show]="erroTipoJornada"
				mensagem="Campo obrigatório"
				data-cy="erro-campo-jornada-semanal"
			></sgp-message-feedback>
		</div>
	</div>
</div>

<div class="row mt-2">
	<div class="col-sm-4">
		<div class="br-input">
			<label for="gratificacao-oportunidade">Incentivo/gratificação (opcional)</label>
			<p-autoComplete
				id="gratificacao-oportunidade"
				[(ngModel)]="oportunidade$.incentivo"
				[showEmptyMessage]="true"
				[suggestions]="filteredGraficacao"
				(completeMethod)="filterGratificacao($event)"
				field="descricao"
				[minLength]="3"
				[size]="200"
				[dropdown]="true"
				[forceSelection]="true"
				emptyMessage="Nenhuma opção encontrada"
				data-cy="campo-incentivo"
			>
			</p-autoComplete>
		</div>
	</div>
</div>

<div class="row mt-2">
	<div class="col-sm-4">
		<div class="br-input" [ngClass]="{'danger': erroQuantidadeVagas}">
			<label for="vagas-oportunidade" #labelQuantidadeVagas>Quantidade de vagas para esta oportunidade</label>
			<p-inputNumber
				[(ngModel)]="oportunidade$.numeroVagas"
				[showButtons]="false"
				mode="decimal"
				inputId="vagas-oportunidade"
				[min]="1"
				[max]="999"
				data-cy="campo-vagas"
			>
			</p-inputNumber>
			<sgp-message-feedback
				[show]="erroQuantidadeVagas"
				mensagem="Campo obrigatório"
				data-cy="erro-campo-vagas"
			></sgp-message-feedback>
		</div>
	</div>
</div>

<div>
	<h5>Requisitos da oportunidade</h5>

	<p class="text-up-01">
		Adicione os requisitos necessários ou desejáveis para que o candidato possa participar da seleção dessa vaga. Deve ser adicionado
		obrigatoriamente pelo menos um requisito diferente da opção Outro para permitir a publicação.
	</p>

	<sgp-estatisticas-oportunidade
		[idOportunidade]="oportunidade$.id"
		[oportunidade]="oportunidade$"
		[possuiRequisito]="possuiRequisito"
	></sgp-estatisticas-oportunidade>

	<sgp-requisitos-oportunidade
		[oportunidade]="oportunidade$"
		(oportunidadeComRequisitos)="updateOportunidade($event)"
	></sgp-requisitos-oportunidade>
</div>

<div class="row mt-4 mb-4">
	<div class="col-sm-12 text-right">
		<div class="br-input">
			<button class="secondary mt-3 mt-sm-0 ml-sm-3" (click)="cancelar()" type="button" pButton data-cy="botao-cancelar-oportunidade">
				Cancelar
			</button>
			<button
				class="primary mt-3 mt-sm-0 ml-sm-3"
				(click)="salvarOportunidade()"
				type="button"
				pButton
				data-cy="botao-salvar-oportunidade"
			>
				{{labelBotaoSalvar}}
			</button>
		</div>
	</div>
</div>

<p-dialog
	header="Dicas de preenchimento"
	[(visible)]="exibirDica"
	[modal]="true"
	[draggable]="false"
	[closeOnEscape]="true"
	[closable]="true"
	[baseZIndex]="10000"
	[resizable]="false"
	styleClass="modal-40vw"
	[contentStyle]="{overflow: 'hidden'}"
	(onHide)="exibirDica = false"
	data-cy="modal-dica-pesquisa"
>
	<sgp-dica-preenchimento-editor></sgp-dica-preenchimento-editor>

	<ng-template pTemplate="footer">
		<p-button (click)="exibirDica = false" label="Fechar" styleClass="br-button secondary" data-cy="botao-fechar-dica"></p-button>
	</ng-template>
</p-dialog>
