import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';

import { AppRoutingModule } from '@app/app-routing.module';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CadastrarAnexosComponent } from '@cadastrarEdital/cadastrar-anexos/cadastrar-anexos.component';
import { CadastrarEditalComponent } from '@cadastrarEdital/cadastrar-edital/cadastrar-edital.component';
import { CadastrarEtapasComponent } from '@cadastrarEdital/cadastrar-etapas/cadastrar-etapas.component';
import { CadastrarOportunidadesComponent } from '@cadastrarEdital/cadastrar-oportunidades/cadastrar-oportunidades.component';
import { CalendarModule } from 'primeng/calendar';
import { CheckboxModule } from 'primeng/checkbox';
import { CommonModule } from '@angular/common';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { CoreModule } from '@core/core.module';
import { DadosBasicosEditalComponent } from '@cadastrarEdital/dados-basicos-edital/dados-basicos-edital.component';
import { DataViewModule } from 'primeng/dataview';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { EditorModule } from 'primeng/editor';
import { EstatisticasOportunidadeComponent } from '@cadastrarEdital/estatisticas-oportunidade/estatisticas-oportunidade.component';
import { ExibirRequisitosOportunidadeComponent } from '@cadastrarEdital/exibir-requisitos-oportunidade/exibir-requisitos-oportunidade.component';
import { FileUploadModule } from 'primeng/fileupload';
import { FormsModule } from '@angular/forms';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputTextModule } from 'primeng/inputtext';
import { ListarAnexosComponent } from '@cadastrarEdital/listar-anexos/listar-anexos.component';
import { ListarEtapasComponent } from '@cadastrarEdital/listar-etapas/listar-etapas.component';
import { ListarOportunidadesComponent } from '@cadastrarEdital/listar-oportunidades/listar-oportunidades.component';
import { ListboxModule } from 'primeng/listbox';
import { MessageModule } from 'primeng/message';
import { MessagesModule } from 'primeng/messages';
import { MultiSelectModule } from 'primeng/multiselect';
import { PanelModule } from 'primeng/panel';
import { RadioButtonModule } from 'primeng/radiobutton';
import { RatingModule } from 'primeng/rating';
import { RequisitosOportunidadeComponent } from '@cadastrarEdital/requisitos-oportunidade/requisitos-oportunidade.component';
import { RevisarEditalComponent } from '@cadastrarEdital/revisar-edital/revisar-edital.component';
import { SharedModule } from '@shared/shared.module';
import { SigepeSegurancaModule } from '@sigepe/ngx-sigepe-seguranca';
import { SkeletonCadastrarEditalComponent } from '@cadastrarEdital/skeleton-cadastrar-edital/skeleton-cadastrar-edital.component';
import { SkeletonModule } from 'primeng/skeleton';
import { TableModule } from 'primeng/table';
import { environment } from '@environments/environment';

@NgModule({
	declarations: [
		CadastrarAnexosComponent,
		CadastrarEditalComponent,
		CadastrarEtapasComponent,
		CadastrarOportunidadesComponent,
		DadosBasicosEditalComponent,
		EstatisticasOportunidadeComponent,
		ExibirRequisitosOportunidadeComponent,
		ListarAnexosComponent,
		ListarEtapasComponent,
		ListarOportunidadesComponent,
		RevisarEditalComponent,
		RequisitosOportunidadeComponent,
		SkeletonCadastrarEditalComponent
	],
	imports: [
		BrowserAnimationsModule,
		CommonModule,
		FormsModule,
		CoreModule,
		SharedModule,
		AppRoutingModule,
		AutoCompleteModule,
		CalendarModule,
		ConfirmDialogModule,
		CheckboxModule,
		DataViewModule,
		DialogModule,
		DropdownModule,
		EditorModule,
		FileUploadModule,
		InputNumberModule,
		InputTextModule,
		ListboxModule,
		MessagesModule,
		MessageModule,
		MultiSelectModule,
		PanelModule,
		RatingModule,
		RadioButtonModule,
		TableModule,
		SkeletonModule,
		SigepeSegurancaModule.forRoot(environment.SEGURANCA_CONFIG)
	],
	exports: [],
	schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class CadastrarEditalModule {}
