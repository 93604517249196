import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { OportunidadesApiService } from '@app/core/services/oportunidades-api.service';

import { Subscription } from 'rxjs';

@Component({
	selector: 'sgp-busca-localidade',
	templateUrl: './busca-localidade.component.html'
})
export class BuscaLocalidadeComponent implements OnInit, OnDestroy {
	@Input() id!: string;
	@Input() label!: string;
	@Input() ngModel: any;
	@Output() ngModelUpdated: EventEmitter<any> = new EventEmitter<any>();
	@Input() required? = true;
	@Input() appendTo?: any = null;

	filteredLocalidade!: any[];
	todasLocalidades: any[] = [];
	subscriptions: Subscription[] = [];

	constructor(private oportunidadesService: OportunidadesApiService) {}

	ngOnInit() {
		this.getLocalidades();
	}

	ngOnDestroy(): void {
		this.subscriptions.forEach(subscription => subscription.unsubscribe());
	}

	getLocalidades() {
		this.subscriptions.push(
			this.oportunidadesService.localidades('*').subscribe(dados => {
				if (dados) {
					for (const dado of dados) {
						this.todasLocalidades.push({ name: dado.nome + ' - ' + dado.sigla_uf, code: dado.cod_ibge });
					}
					this.todasLocalidades.sort((a, b) => a.name.localeCompare(b.name));
				}
			})
		);
	}

	/**
	 * Carrega a combo de localidades
	 */
	filterLocalidades(event: any): void {
		const localidadesEncontradas: any[] = [];

		const query = event.query;
		this.todasLocalidades.forEach((localidade: { name: string }) => {
			if (localidade.name.toLowerCase().indexOf(query.toLowerCase()) == 0) {
				localidadesEncontradas.push(localidade);
			}
		});
		this.filteredLocalidade = localidadesEncontradas;
	}

	/** Atualiza o valor com a opção seleciona */
	atualizarNgModel(event: any) {
		this.ngModelUpdated.emit(event);
	}
}
