import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';

import { CestaCurriculo } from './../../core/models/cesta-curriculo';
import { FeedbackModal } from '@app/core/models/feedback-modal';
import { ItemRetornoCurriculo } from '@pesquisarCurriculo/models/retorno-curriculo';
import { PesquisarCurriculoApiService } from '../services/pesquisar-curriculo-api.service';
import { Subscription } from 'rxjs';
import { TagCestaCurriculo } from './../../core/models/tag-cesta-curriculo';
import { TalentosApiService } from '@app/core/services/talentos-api.service';
import { TopicosCurriculo } from './../../core/models/topicos-curriculo';
import { UiService } from '@core/services/ui.service';

@Component({
	selector: 'sgp-item-resultado-curriculo',
	templateUrl: './item-resultado-curriculo.component.html',
	styles: [
		`
			.cesta {
				text-decoration: none;
				padding: 3px 10px;
				border-radius: 5px;
				font-weight: 500;
				cursor: pointer;
			}

			.cesta.favoritado {
				background: var(--blue-warm-vivid-10);
				color: var(--blue-warm-vivid-80);
			}
			.cesta.nao_favoritado {
				background: var(--blue-warm-vivid-80);
				color: var(--pure-0);
			}

			.cesta .icone::before {
				vertical-align: 1px;
			}
		`
	]
})
export class ItemResultadoCurriculoComponent implements AfterViewInit, OnDestroy {
	TAMANHO_MAXIMO_RESUMO = 350;

	@Input() item!: ItemRetornoCurriculo;
	@Output() repesquisar: EventEmitter<FeedbackModal> = new EventEmitter<FeedbackModal>();
	leiaMais = false;
	resumoCompleto?: string;
	exibirCurriculo = false;
	exibirIA = false;
	classificacao?: string;
	probabilidade?: string;
	erroIA = false;
	favoritado = false;
	classeFavoritado = 'nao_favoritado';
	operacaoCesta = 'plus';
	titleOperacaoCesta = 'Permite adicionar currículo na cesta';
	exibirModalEditarFavoritos = false;
	subscriptions: Subscription[] = [];
	cesta?: CestaCurriculo;
	tagsSelecionadas?: TagCestaCurriculo[] = [];
	topicosCurriculo?: TopicosCurriculo[] = [];
	vetorNomeTags: string[] = [];
	vetorTopicos: [string[]] = [[]];
	

	constructor(
		private pesquisarCurriculoApi: PesquisarCurriculoApiService,
		private talentosApi: TalentosApiService,
		private ui: UiService
	) {}

	ngAfterViewInit(): void {
		this.resumoCompleto = this.item.resumoCurriculo;
		//FIXME removido o serviço 'consultarServicoIA' porque está dando internal server error na homologação
		this.consultarServicoIA();
		this.inicioFavoritando();
		this.preencherTags();
	}

	ngOnDestroy(): void {
		this.subscriptions.forEach(subscription => subscription.unsubscribe());
	}

	preencherTags() {
		if (this.item!.idCestaCurriculo !== null) {
			this.subscriptions.push(
				this.talentosApi.consultarCestaCurriculo(this.item.idCestaCurriculo!).subscribe(
					res => {
						this.cesta = res;
						this.tagsSelecionadas = this.cesta.tags;
						this.vetorNomeTags = this.ui.tratarVetorNomeTags(this.tagsSelecionadas!);
					},
					err => {
						// eslint-disable-next-line security-node/detect-crlf
						console.log(err);
					}
				)
			);
		}
	}

	consultarServicoIA() {
		this.subscriptions.push(
			this.pesquisarCurriculoApi.consultarServicoIA(this.item.idCurriculo!).subscribe(res => {
				this.vetorTopicos = this.ui.tratarVetorTopicos(res);
				// eslint-disable-next-line security-node/detect-crlf
				console.log('Vetor Topicos' + this.vetorTopicos);
			})
		);
	}

	exibirModalCurriculo() {
		this.exibirCurriculo = true;
	}

	fecharModalCurriculo(event: boolean) {
		this.exibirCurriculo = event;
	}

	exibirModalFavoritar() {
		this.exibirModalEditarFavoritos = true;
	}

	inicioFavoritando() {
		this.favoritado = this.item.idCestaCurriculo ? true : false;
		this.definirEstiloBotaoCesta();
		this.exibirModalEditarFavoritos = false;
	}

	favoritando(feedback: FeedbackModal) {
		this.favoritado = feedback.exibirModal;
		this.exibirModalEditarFavoritos = feedback.exibirModal;
		this.definirEstiloBotaoCesta();
		this.repesquisar.emit(feedback);
	}

	private definirEstiloBotaoCesta() {
		if (this.item.idCestaCurriculo) {
			this.classeFavoritado = 'favoritado';
			this.operacaoCesta = 'minus';
			this.titleOperacaoCesta = 'Permite excluir currículo da cesta';
		} else {
			this.classeFavoritado = 'nao_favoritado';
			this.operacaoCesta = 'plus';
			this.titleOperacaoCesta = 'Permite adicionar currículo na cesta';
		}
	}
}
