import * as moment from 'moment';

import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { ConfirmationService, Message, MessageService, PrimeNGConfig } from 'primeng/api';

import { CadastrarEditalApiService } from '../services/cadastrar-edital-api.service';
import { ConsultasApiService } from '../services/consultas-api.service';
import { Edital } from '@core/models/edital';
import { Etapa } from '@core/models/etapa';
import { SituacoesEnum } from '@core/enums/situacoes.enum';
import { Subscription } from 'rxjs';
import { TipoDocumento } from '@app/core/models/tipo-documento';
import { TipoEtapa } from '@core/models/tipo-etapa';
import { TranslateService } from '@ngx-translate/core';
import { UiService } from '@core/services/ui.service';
import { ValidateHelper } from '@core/helpers/validate.helper';

@Component({
	selector: 'sgp-cadastrar-etapas',
	templateUrl: './cadastrar-etapas.component.html',
	providers: [MessageService, ConfirmationService]
})
export class CadastrarEtapasComponent implements OnInit, OnDestroy {
	@Input() idEdital!: number;
	@Input() idEtapa?: number;
	@Input() operacao!: string;
	@Input() situacao!: any;
	@Input() etapasCadastradas!: Etapa[];
	@Output() salvaComSucesso: EventEmitter<any> = new EventEmitter<any>();
	@Output() obterOperacao: EventEmitter<string> = new EventEmitter<string>();

	@ViewChild('tipoEtapa') tipoEtapa!: ElementRef;
	@ViewChild('mensagemErro') mensagemErro!: ElementRef;

	etapa$?: Etapa;
	labelBotaoSalvar = '';
	erroDataFinal = false;
	erroTipoEtapa = false;
	erroTipoDocumento = false;
	msgErro: Message[] = [];
	exibirDica = false;
	inibeDataInicio = false;

	descricaoEtapa?: string;
	orientacoesEtapa?: string;
	informacaoCandidatura?: string;

	filteredTipoEtapas!: TipoEtapa[];
	tipoEtapas!: TipoEtapa[];

	listaTiposDocumento: TipoDocumento[] = [];

	subscriptions: Subscription[] = [];

	constructor(
		private config: PrimeNGConfig,
		private cadastrarEditalApi: CadastrarEditalApiService,
		private consultasOportunidadesApi: ConsultasApiService,
		private translateService: TranslateService,
		private validate: ValidateHelper,
		private ui: UiService
	) {}

	ngOnInit(): void {
		this.detalharEtapa();
		this.getTiposEtapas();
		this.getTiposDocumento();
		this.setCalendarioParaPortugues();
	}

	ngOnDestroy(): void {
		this.subscriptions.forEach(subscription => subscription.unsubscribe());
	}

	/**
	 * Configura o componentes de calendário para português
	 */
	setCalendarioParaPortugues() {
		this.translateService.setDefaultLang('pt');
		this.translateService.use('pt');
		this.subscriptions.push(this.translateService.get('primeng').subscribe(res => this.config.setTranslation(res)));
	}

	atualizarEtapa() {
		this.msgErro = [];
		if (this.etapa$ && this.etapa$.id && this.eValido()) {
			this.formatarDatas();
			this.subscriptions.push(
				this.cadastrarEditalApi.alterarEtapa(this.etapa$.id, this.etapa$).subscribe(
					// eslint-disable-next-line @typescript-eslint/no-unused-vars
					res => {
						this.salvaComSucesso.emit();
					},
					err => {
						this.msgErro = this.ui.criarListaMensagem('error', '', err.error.message);
						this.mensagemErro.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
					}
				)
			);
		} else {
			this.mensagemErro.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
		}
	}

	incluirEtapa() {
		this.msgErro = [];
		if (this.etapa$ && this.eValido()) {
			this.formatarDatas();
			this.subscriptions.push(
				this.cadastrarEditalApi.incluirEtapa(this.idEdital, this.etapa$).subscribe(
					// eslint-disable-next-line @typescript-eslint/no-unused-vars
					res => {
						this.salvaComSucesso.emit();
					},
					err => {
						this.msgErro = this.ui.criarListaMensagem('error', '', err.error.message);
						this.mensagemErro.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
					}
				)
			);
		} else {
			this.mensagemErro.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
		}
	}

	cancelar() {
		this.etapa$ = {};
		this.operacao = 'listar';
		this.obterOperacao.emit(this.operacao);
	}

	filterTipoEtapa(event: any): void {
		const filtered: TipoEtapa[] = [];
		const query = event.query;

		if (this.tipoEtapas) {
			for (const etapa of this.tipoEtapas) {
				if (etapa.descricao?.toLowerCase().indexOf(query.toLowerCase()) != -1) {
					filtered.push(etapa);
				}
			}
		}

		this.filteredTipoEtapas = filtered;
	}

	limparTiposDocumento() {
		this.erroTipoDocumento = false;
		this.etapa$!.tiposDocumento = [];
	}

	private detalharEtapa() {
		this.msgErro = [];
		if (this.idEtapa) {
			this.subscriptions.push(
				this.cadastrarEditalApi.detalharEtapa(this.idEtapa).subscribe(
					res => {
						if (res) {
							this.etapa$ = res;
							this.conversaoListaTiposDocumento(res.tiposDocumento);
							const dataInicio = this.converterParaData(res.dataInicioEtapa);
							if (moment(dataInicio).isBefore(new Date()) && this.situacao.codigo === SituacoesEnum.RET) {
								this.inibeDataInicio = true;
							} else {
								this.inibeDataInicio = false;
							}
						}
					},
					err => {
						this.msgErro = this.ui.criarListaMensagem('error', '', err.error.message);
						this.mensagemErro.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
					}
				)
			);
		} else {
			this.etapa$ = {};
		}
	}

	private conversaoListaTiposDocumento(tiposDocumentoEtapa: any) {
		if (this.etapa$ && tiposDocumentoEtapa.length > 0) {
			this.etapa$.tiposDocumento = [];
			for (const item of tiposDocumentoEtapa) {
				const documento = new TipoDocumento(item.tipoDocumento.id, item.tipoDocumento.nome, item.tipoDocumento.ativo);
				this.etapa$.tiposDocumento.push(documento);
			}
		}
	}

	private getTiposEtapas() {
		this.tipoEtapas = [];
		this.subscriptions.push(
			this.consultasOportunidadesApi.tiposEtapa().subscribe(
				dados => {
					if (dados !== null) {
						dados.forEach(dado => {
							this.tipoEtapas.push({
								descricao: dado.descricao,
								id: dado.id,
								sugereAutomaticamente: dado.sugereAutomaticamente
							});
						});
					}
				},
				err => {
					this.msgErro = this.ui.criarListaMensagem('error', '', err.error.message);
					this.mensagemErro.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
				}
			)
		);
	}

	private getTiposDocumento() {
		this.listaTiposDocumento = [];
		this.subscriptions.push(
			this.consultasOportunidadesApi.tiposDocumentos().subscribe(
				dados => {
					if (dados !== null) {
						this.listaTiposDocumento = dados.items;
						this.adicionarItemInativo();
					}
				},
				err => {
					this.msgErro = this.ui.criarListaMensagem('error', '', err.error.message);
					this.mensagemErro.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
				}
			)
		);
	}

	private adicionarItemInativo() {
		if (this.etapa$ && this.etapa$.tiposDocumento) {
			for (const tipoDocumento of this.etapa$.tiposDocumento) {
				const encontrou = this.listaTiposDocumento.find(x => x.id == tipoDocumento.id);
				if (!encontrou) {
					this.listaTiposDocumento.push(tipoDocumento);
					this.listaTiposDocumento = this.listaTiposDocumento.sort((a, b) => (a.nome! < b.nome! ? -1 : 1));
				}
			}
		}
	}

	private eValido(): boolean {
		if (
			!this.validarTipoEtapaEdital() ||
			!this.validarUnicidadeTipoEtapa() ||
			!this.validarDataFinalMaiorIgualDataInicial() ||
			!this.validarSobreposicaoDatas() ||
			!this.validarDatasPosteriorDataAtual() ||
			!this.validarPreenchimentoTipoDocumento()
		) {
			return false;
		}
		return true;
	}

	private validarTipoEtapaEdital(): boolean {
		if (this.validate.isEmBranco(this.etapa$?.tipoEtapaEdital)) {
			this.erroTipoEtapa = true;
			this.tipoEtapa.nativeElement.scrollIntoView();
			return false;
		} else {
			this.erroTipoEtapa = false;
		}
		return true;
	}

	private validarDataFinalMaiorIgualDataInicial(): boolean {
		if (this.etapa$ && this.etapa$.dataInicioEtapa !== undefined && this.etapa$.dataFimEtapa !== undefined) {
			const dataInicio = this.converterParaData(this.etapa$.dataInicioEtapa);
			const dataFim = this.converterParaData(this.etapa$.dataFimEtapa);

			if (moment(dataFim).isBefore(dataInicio)) {
				this.erroDataFinal = true;
				return false;
			} else {
				this.erroDataFinal = false;
			}
		}
		return true;
	}

	private converterParaData(data: any) {
		if (typeof data === 'string') {
			const dia = +data.substring(0, 2);
			const mes = +data.substring(3, 5);
			const ano = +data.substring(6);
			return moment(new Date(ano, mes - 1, dia)).startOf('day');
		} else {
			return moment(data);
		}
	}

	private validarDatasPosteriorDataAtual(): boolean {
		if (this.etapa$ && this.etapa$.dataInicioEtapa) {
			const agora = moment();
			if (moment(this.etapa$.dataInicioEtapa).isBefore(agora)) {
				this.msgErro = this.ui.criarListaMensagem('error', '', 'A etapa deve iniciar após a data atual.');
				return false;
			}
		}
		this.msgErro = [];
		return true;
	}

	private validarUnicidadeTipoEtapa(): boolean {
		if (this.isOperacaoCadastrar() && this.etapasCadastradas && this.etapa$?.tipoEtapaEdital) {
			for (const etapaCadastrada of this.etapasCadastradas) {
				if (this.ehAMesmaEtapa(etapaCadastrada)) {
					this.msgErro = this.ui.criarListaMensagem('error', '', 'Não é permitido ter duas etapas com o mesmo tipo no edital.');
					return false;
				}
			}
			this.msgErro = [];
			return true;
		}
		this.msgErro = [];
		return true;
	}

	private ehAMesmaEtapa(etapaCadastrada: Etapa) {
		return (
			etapaCadastrada.tipoEtapaEdital &&
			this.etapa$?.tipoEtapaEdital &&
			etapaCadastrada.tipoEtapaEdital.id === this.etapa$.tipoEtapaEdital.id
		);
	}

	private validarSobreposicaoDatas(): boolean {
		if (this.etapa$ && this.etapasCadastradas && this.etapa$.dataInicioEtapa && this.etapa$.dataFimEtapa) {
			for (const etapaCadastrada of this.etapasCadastradas) {
				if (
					this.dataNoIntervaloDaEtapa(this.etapa$.dataInicioEtapa, etapaCadastrada) ||
					this.dataNoIntervaloDaEtapa(this.etapa$.dataFimEtapa, etapaCadastrada)
				) {
					this.msgErro = this.ui.criarListaMensagem(
						'error',
						'',
						'Não é permitida a sobreposição de datas entre as etapas do edital. Verifique as datas das demais etapas cadastradas.'
					);
					return false;
				}
			}
			this.msgErro = [];
			return true;
		}
		this.msgErro = [];
		return true;
	}

	private validarPreenchimentoTipoDocumento(): boolean {
		if (this.etapa$ && this.etapa$.permiteEnvioDocumentos && this.etapa$.tiposDocumento?.length == 0) {
			this.erroTipoDocumento = true;
			return false;
		}
		this.erroTipoDocumento = false;
		this.msgErro = [];
		return true;
	}

	private dataNoIntervaloDaEtapa(data: Date, etapaExistente: Etapa): boolean {
		if (etapaExistente.id !== this.etapa$?.id && etapaExistente.dataInicioEtapa && etapaExistente.dataFimEtapa) {
			const dataInformada = moment(data, 'DD-MM-YYYY');
			const dataInicio = moment(etapaExistente.dataInicioEtapa, 'DD-MM-YYYY');
			const dataFim = moment(etapaExistente.dataFimEtapa, 'DD-MM-YYYY');
			return moment(dataInformada).isBetween(dataInicio, dataFim, undefined, '[]');
		}
		return false;
	}

	private isOperacaoCadastrar(): boolean {
		return this.operacao === 'cadastrar';
	}

	private formatarDatas() {
		if (this.etapa$ && this.etapa$.dataInicioEtapa && typeof this.etapa$.dataInicioEtapa !== 'string') {
			this.etapa$.dataInicioEtapa = moment(this.etapa$.dataInicioEtapa).format('DD/MM/YYYY');
		}
		if (this.etapa$ && this.etapa$.dataFimEtapa && typeof this.etapa$.dataFimEtapa !== 'string') {
			this.etapa$.dataFimEtapa = moment(this.etapa$.dataFimEtapa).format('DD/MM/YYYY');
		}
	}
}
