import { Component, Input, OnDestroy, OnInit } from '@angular/core';

import { DashboardApiService } from '../services/dashboard-api.service';
import { QuantitativosDashboard } from '@dashboard/models/quantitativos-dashboard';
import { Subscription } from 'rxjs';

@Component({
	selector: 'sgp-dashboard-gestor-geral',
	templateUrl: './dashboard-gestor-geral.component.html'
})
export class DashboardGestorGeralComponent implements OnInit, OnDestroy {
	@Input() dadosUsuario!: any;
	@Input() nomeUsuario?: string;
	orgaoSelecionado?: any;
	codigoOrgaoSelecionado?: number;
	quantitativos = new QuantitativosDashboard();
	editaisPorSituacao?: any[];
	evolucaoMensalEditaisPublicados?: any[];
	evolucaoMensalCandidatosInscritos?: any[];
	oportunidadesPorMovimentacao?: any[];

	orgaosComMaisEditaisPublicados?: any[];
	orgaosComMaisCandidatosInscritos?: any[];
	paginaOrgaosComMaisEditaisPublicados = 1;
	paginaOrgaosComMaisCandidatosInscritos = 1;
	orgaosComMaisOportunidadesPublicadas?: any[];

	uorgsComMaisEditaisPublicados?: any[];
	uorgsComMaisCandidatosInscritos?: any[];
	paginaUorgsComMaisEditaisPublicados = 1;
	paginaUorgsComMaisCandidatosInscritos = 1;
	paginaOrgaosComMaisOportunidadesPublicadas = 1;

	subscriptions: Subscription[] = [];

	constructor(private dashboardService: DashboardApiService) {}

	ngOnInit() {
		this.carregarDadosDashboard();
	}

	ngOnDestroy(): void {
		this.subscriptions.forEach(subscription => subscription.unsubscribe());
	}

	atualizarOrgao(event: any) {
		this.orgaoSelecionado = event;
		this.codigoOrgaoSelecionado = event.code;
		this.carregarDadosDashboard();
	}

	navegarOrgaosComMaisEditaisPublicados(event: number) {
		this.paginaOrgaosComMaisEditaisPublicados = event;
		this.getOrgaosComMaisEditaisPublicados();
	}

	navegarOrgaosComMaisCandidatosInscritos(event: number) {
		this.paginaOrgaosComMaisCandidatosInscritos = event;
		this.getOrgaosComMaisCandidatosInscritos();
	}

	navegarUorgsComMaisEditaisPublicados(event: number) {
		this.paginaUorgsComMaisEditaisPublicados = event;
		this.getUorgsComMaisEditaisPublicados();
	}

	navegarUorgsComMaisCandidatosInscritos(event: number) {
		this.paginaUorgsComMaisCandidatosInscritos = event;
		this.getUorgsComMaisCandidatosInscritos();
	}

	navegarOrgaosComMaisOportunidadesPublicadas(event: number) {
		this.paginaOrgaosComMaisOportunidadesPublicadas = event;
		this.getOrgaosComMaisOportunidadesPublicadas();
	}

	private carregarDadosDashboard() {
		this.getTotalizadores();
		this.getEditaisPorSituacao();
		this.getEvolucaoMensal();
		if (!this.codigoOrgaoSelecionado) {
			this.getOrgaosComMaisEditaisPublicados();
			this.getOrgaosComMaisCandidatosInscritos();
			this.getOrgaosComMaisOportunidadesPublicadas();
		} else {
			this.getUorgsComMaisEditaisPublicados();
			this.getUorgsComMaisCandidatosInscritos();
			this.getOrgaosComMaisOportunidadesPublicadas();
		}
		this.getOportunidadesPorMovimentacao();
	}

	private getOportunidadesPorMovimentacao() {
		this.subscriptions.push(
			this.dashboardService.dashboardOportunidadesPorTipoMovimentacao(Number(this.codigoOrgaoSelecionado)).subscribe(async res => {
				this.oportunidadesPorMovimentacao = [
					{
						legenda: res.tooltip,
						dados: res.dados,
						total: res.dados.length > 0 ? res.dados.reduce((a: any, b: any) => a + b) : 0
					}
				];
			})
		);
	}

	private getTotalizadores() {
		this.subscriptions.push(
			this.dashboardService.dashboardTotalizadores(this.codigoOrgaoSelecionado).subscribe(async res => {
				this.quantitativos.quantidadeEditaisPublicados = res.qtdEditais;
				this.quantitativos.quantidadeOportunidadesPublicadas = res.qtdOportunidades;
				this.quantitativos.quantidadeOrgaos = res.qtdOrgaos;
				this.quantitativos.quantidadeUorgs = res.qtdUorgs;
			})
		);
	}

	private getEditaisPorSituacao() {
		this.subscriptions.push(
			this.dashboardService.dashboardEditaisPorSituacao(this.codigoOrgaoSelecionado).subscribe(async res => {
				this.editaisPorSituacao = [
					{
						legenda: res.legenda,
						dados: res.dados,
						total: res.dados.length > 0 ? res.dados.reduce((a: any, b: any) => a + b) : 0
					}
				];
			})
		);
	}

	private getEvolucaoMensal() {
		this.subscriptions.push(
			this.dashboardService.dashboardEvolucaoMensal(this.codigoOrgaoSelecionado).subscribe(resultado => {
				this.evolucaoMensalEditaisPublicados = [];
				this.evolucaoMensalCandidatosInscritos = [];

				this.evolucaoMensalEditaisPublicados?.push({
					id: 1,
					grupo: resultado[0].titulo,
					legenda: resultado[0].legenda,
					dados: resultado[0].dados
				});
				this.evolucaoMensalCandidatosInscritos?.push({
					id: 1,
					grupo: resultado[1].titulo,
					legenda: resultado[1].legenda,
					dados: resultado[1].dados
				});
			})
		);
	}

	private getOrgaosComMaisEditaisPublicados() {
		const itensPorPagina = 10;
		this.subscriptions.push(
			this.dashboardService
				.dashboardEditaisPublicadosPorOrgao(this.paginaOrgaosComMaisEditaisPublicados, itensPorPagina)
				.subscribe(async res => {
					this.orgaosComMaisEditaisPublicados = [
						{
							legenda: res.legenda,
							dados: res.dados,
							total: res.qtdOrgaos
						}
					];
				})
		);
	}

	private getOrgaosComMaisCandidatosInscritos() {
		const itensPorPagina = 10;
		this.subscriptions.push(
			this.dashboardService
				.dashboardCandidatosInscritosPorOrgao(this.paginaUorgsComMaisCandidatosInscritos, itensPorPagina)
				.subscribe(async res => {
					this.orgaosComMaisCandidatosInscritos = [
						{
							legenda: res.legenda,
							dados: res.dados,
							total: res.qtdOrgaos
						}
					];
					if (!this.codigoOrgaoSelecionado) {
						this.quantitativos.quantidadeCandidatosInscritos =
							res.dados.length > 0 ? res.dados.reduce((a: any, b: any) => a + b) : 0;
					}
				})
		);
	}

	private getUorgsComMaisEditaisPublicados() {
		const itensPorPagina = 10;
		this.subscriptions.push(
			this.dashboardService
				.dashboardEditaisPublicadosPorUorg(this.codigoOrgaoSelecionado!, this.paginaUorgsComMaisCandidatosInscritos, itensPorPagina)
				.subscribe(async res => {
					this.uorgsComMaisEditaisPublicados = [
						{
							legenda: res.legenda,
							dados: res.dados,
							tooltip: res.tooltip,
							total: res.qtdUorgs
						}
					];
				})
		);
	}

	private getUorgsComMaisCandidatosInscritos() {
		const itensPorPagina = 10;
		this.subscriptions.push(
			this.dashboardService
				.dashboardCandidatosInscritosPorUorg(
					this.codigoOrgaoSelecionado!,
					this.paginaUorgsComMaisCandidatosInscritos,
					itensPorPagina
				)
				.subscribe(async res => {
					if (res) {
						this.uorgsComMaisCandidatosInscritos = [
							{
								legenda: res.legenda,
								dados: res.dados,
								tooltip: res.tooltip,
								total: res.qtdOrgaos
							}
						];

						if (res.dados.length > 0) {
							this.quantitativos.quantidadeCandidatosInscritos =
								res.dados.length > 0 ? res.dados.reduce((a: any, b: any) => a + b) : 0;
						} else {
							this.quantitativos.quantidadeCandidatosInscritos = '0';
						}
					}
				})
		);
	}

	private getOrgaosComMaisOportunidadesPublicadas() {
		const itensPorPagina = 10;
		this.subscriptions.push(
			this.dashboardService
				.dashboardOportunidadesPublicadasPorOrgao(this.paginaOrgaosComMaisCandidatosInscritos, itensPorPagina)
				.subscribe(async res => {
					this.orgaosComMaisOportunidadesPublicadas = [
						{
							legenda: res.legenda,
							dados: res.dados,
							tooltip: res.tooltip,
							total: res.qtdUorgs
						}
					];
				})
		);
	}
}
