import * as moment from 'moment';

import { Component, EventEmitter, Input, Output } from '@angular/core';

import { Edital } from '@core/models/edital';

@Component({
	selector: 'sgp-card-editais',
	templateUrl: './card-editais.component.html',
	styleUrls: ['./card-editais.component.scss']
})
export class CardEditaisComponent {
	@Input() titulo!: string;
	@Input() editais: Edital[] = [];
	@Input() pendentes = false;
	@Input() loading = true;
	@Input() totalResultado?: number;
	@Input() primeiroItemResultado = 0;
	@Output() paginarEdital: EventEmitter<any> = new EventEmitter<any>();
	@Output() itensPorPagina: EventEmitter<any> = new EventEmitter<any>();

	itens = 5;
	moment: any = moment;

	paginar(event: any) {
		this.paginarEdital.emit(event);
	}

	getItensPorPagina(event: any) {
		this.itens = event.rows;
		this.itensPorPagina.emit(event.rows);
	}
}
