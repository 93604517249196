import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';

import { DashboardApiService } from '../services/dashboard-api.service';
import { EditalEmAndamento } from './../models/edital-andamento';
import { EditalPendente } from './../models/edital-pendente';
import { OportunidadesApiService } from '@app/core/services/oportunidades-api.service';
import { PerfisEnum } from '@app/core/enums/perfis.enum';
import { QuantitativosDashboard } from '@dashboard/models/quantitativos-dashboard';
import { Subscription } from 'rxjs';
import { TabelaEdital } from './../models/tabela-edital';

@Component({
	selector: 'sgp-dashboard-gestor-oportunidades-autorizador',
	templateUrl: './dashboard-gestor-oportunidades-autorizador.component.html',
	styleUrls: ['./dashboard-gestor-oportunidades-autorizador.component.scss']
})
export class DashboardGestorOportunidadesAutorizadorComponent implements OnInit, OnDestroy {
	ITENS_POR_PAGINA = 10;

	@Input() dadosUsuario!: any;
	@Input() nomeUsuario?: string;
	@Input() eAutorizador = false;
	@Output() mensagemErro: EventEmitter<string> = new EventEmitter<string>();

	orgaoUsuario?: number;
	nomeOrgao?: string;
	quantitativos = new QuantitativosDashboard();
	editaisPorSituacao?: any[];
	evolucaoMensalEditaisPublicados?: any[];
	evolucaoMensalCandidatosInscritos?: any[];
	oportunidadesPorMovimentacao?: any[];

	editaisEmAndamento: EditalEmAndamento[] = [];
	tabelaEditaisEmAndamento = new TabelaEdital();

	editaisComPendencias: EditalPendente[] = [];
	tabelaEditaisComPendencias = new TabelaEdital();

	exibirPainelEditaisPendentes = true;
	exibirPainelUltimosEditais = false;

	uorgsComMaisEditaisPublicados?: any[];
	uorgsComMaisCandidatosInscritos?: any[];
	uorgsComMaisOportunidadesPublicadas?: any[];
	paginaUorgsComMaisEditaisPublicados = 1;
	paginaUorgsComMaisCandidatosInscritos = 1;
	paginaUorgsComMaisOportunidadesPublicadas = 1;

	tabSelecionada = 1;

	subscriptions: Subscription[] = [];

	constructor(private dashboardService: DashboardApiService, private oportunidadesService: OportunidadesApiService) {}

	ngOnInit() {
		this.obterNomeOrgao();
		this.carregarDadosDashboard();
	}

	ngOnDestroy(): void {
		this.subscriptions.forEach(subscription => subscription.unsubscribe());
	}

	get perfisEnum(): typeof PerfisEnum {
		return PerfisEnum;
	}

	navegarUorgsComMaisEditaisPublicados(event: number) {
		this.paginaUorgsComMaisEditaisPublicados = event;
		this.getUorgsComMaisEditaisPublicados();
	}

	navegarUorgsComMaisCandidatosInscritos(event: number) {
		this.paginaUorgsComMaisCandidatosInscritos = event;
		this.getUorgsComMaisCandidatosInscritos();
	}

	navegarUorgsComMaisOportunidadesPublicadas(event: number) {
		this.paginaUorgsComMaisOportunidadesPublicadas = event;
		this.getUorgsComMaisOportunidadesPublicadas();
	}

	paginarEditaisEmAndamento(event: any) {
		this.tabelaEditaisEmAndamento.paginaAtual = event.first / event.rows + 1;
		this.tabelaEditaisEmAndamento.primeiroItem = event.first;
		this.tabelaEditaisEmAndamento.itensPorPagina = event.rows;
		this.getEditaisEmAndamento();
	}
	obterItensEditaisEmAndamento(event: any) {
		this.tabelaEditaisEmAndamento.itensPorPagina = event.rows;
	}

	paginarEditaisPendencias(event: any) {
		this.tabelaEditaisComPendencias.paginaAtual = event.first / event.rows + 1;
		this.tabelaEditaisComPendencias.primeiroItem = event.first;
		this.tabelaEditaisComPendencias.itensPorPagina = event.rows;
		this.getEditaisComPendencias();
	}
	obterItensEditaisPendencias(event: any) {
		this.tabelaEditaisComPendencias.itensPorPagina = event.rows;
	}

	trocarExibicaoPaineisEdital(tipo: string) {
		if (tipo === 'pendentes') {
			this.exibirPainelEditaisPendentes = true;
			this.exibirPainelUltimosEditais = false;
		} else {
			this.exibirPainelEditaisPendentes = false;
			this.exibirPainelUltimosEditais = true;
		}
	}

	trocarTab(tab: number) {
		console.log(tab);
		this.tabSelecionada = tab;
	}

	private obterNomeOrgao() {
		this.orgaoUsuario = Number(this.dadosUsuario.codigoOrgaoSiape);
		this.subscriptions.push(
			this.oportunidadesService.consultarOrgao(this.orgaoUsuario.toString()).subscribe(orgao => {
				if (orgao) {
					this.nomeOrgao = orgao.nome;
				}
			})
		);
	}

	private carregarDadosDashboard() {
		this.getTotalizadores();
		this.getEditaisPorSituacao();

		this.getEditaisEmAndamento();
		if (this.eAutorizador) {
			this.getEditaisComPendencias();
		}

		this.getEvolucaoMensal();
		this.getOportunidadesPorMovimentacao();
		this.getUorgsComMaisEditaisPublicados();
		this.getUorgsComMaisCandidatosInscritos();
		this.getUorgsComMaisOportunidadesPublicadas();
	}

	private getTotalizadores() {
		this.subscriptions.push(
			this.dashboardService.dashboardTotalizadores(this.orgaoUsuario).subscribe(async res => {
				this.quantitativos.quantidadeEditaisPublicados = res.qtdEditais;
				this.quantitativos.quantidadeOportunidadesPublicadas = res.qtdOportunidades;
				this.quantitativos.quantidadeUorgs = res.qtdUorgs;
			})
		);
	}

	private getEditaisPorSituacao() {
		this.subscriptions.push(
			this.dashboardService.dashboardEditaisPorSituacao(this.orgaoUsuario).subscribe(async res => {
				this.editaisPorSituacao = [
					{
						legenda: res.legenda,
						dados: res.dados,
						total: res.dados.length > 0 ? res.dados.reduce((a: any, b: any) => a + b) : 0
					}
				];
			})
		);
	}

	private async getEditaisEmAndamento() {
		this.criarColunasEditaisEmAndamento();
		this.tabelaEditaisEmAndamento.loading = true;
		this.subscriptions.push(
			this.dashboardService
				.dashboardEditaisAndamento(this.tabelaEditaisEmAndamento.paginaAtual, this.tabelaEditaisEmAndamento.itensPorPagina)
				.subscribe(
					async res => {
						if (res) {
							this.tabelaEditaisEmAndamento.loading = false;
							this.tabelaEditaisEmAndamento.totalResultado = res.metadata!.total;
							this.tabelaEditaisEmAndamento.paginaAtual = res.metadata!.page;
							this.tabelaEditaisComPendencias.itensPorPagina = res.metadata!.size;
							this.editaisEmAndamento = res.items!;
						}
					},
					err => {
						this.tabelaEditaisEmAndamento.loading = false;
						this.tabelaEditaisEmAndamento.totalResultado = 0;
						this.editaisEmAndamento = [];
						this.mensagemErro.emit(err.error.message);
					}
				)
		);
	}

	private criarColunasEditaisEmAndamento() {
		this.tabelaEditaisComPendencias.colunas = [
			{ field: 'id', header: 'id', pipe: '' },
			{ field: 'nomeOrgao', header: 'Órgão', pipe: '' },
			{ field: 'numeroEdital', header: 'Nº edital', pipe: '' },
			{ field: 'dataHoraAtualizacao', header: 'Última atualização', pipe: '' },
			{ field: 'etapaAtual', header: 'Etapa atual', pipe: '' },
			{ field: 'situacao', header: 'Situação', pipe: '' },
			{ field: 'candidatosInscritos', header: 'Candidatos inscritos', pipe: '' }
		];
	}

	private async getEditaisComPendencias() {
		this.criarColunasEditaisComPendencias();
		this.tabelaEditaisComPendencias.loading = true;
		this.subscriptions.push(
			this.dashboardService
				.dashboardEditaisPendentes(this.tabelaEditaisComPendencias.paginaAtual, this.tabelaEditaisComPendencias.itensPorPagina)
				.subscribe(
					async res => {
						if (res) {
							this.tabelaEditaisComPendencias.loading = false;
							this.tabelaEditaisComPendencias.totalResultado = res.metadata!.total;
							this.tabelaEditaisComPendencias.paginaAtual = res.metadata!.page;
							this.tabelaEditaisComPendencias.itensPorPagina = res.metadata!.size;
							this.editaisComPendencias = res.items!;
						}
					},
					err => {
						this.tabelaEditaisComPendencias.loading = false;
						this.tabelaEditaisComPendencias.totalResultado = 0;
						this.editaisComPendencias = [];
						this.mensagemErro.emit(err.error.message);
					}
				)
		);
	}

	private criarColunasEditaisComPendencias() {
		this.tabelaEditaisComPendencias.colunas = [
			{ field: 'id', header: 'id', pipe: '' },
			{ field: 'numeroEdital', header: 'Nº edital', pipe: '' },
			{ field: 'nomeOrgao', header: 'Órgão', pipe: '' },
			{ field: 'etapaAtual', header: 'Etapa atual', pipe: '' },
			{ field: 'dataInicioEtapa', header: 'Prazo', pipe: 'dateago' },
			{ field: 'situacao.descricao', header: 'Situação', pipe: '' },
			{ field: 'dataHoraAtualizacao', header: 'Última atualização', pipe: '' }
		];
	}

	private getEvolucaoMensal() {
		this.subscriptions.push(
			this.dashboardService.dashboardEvolucaoMensal(this.orgaoUsuario).subscribe(resultado => {
				this.evolucaoMensalEditaisPublicados = [];
				this.evolucaoMensalCandidatosInscritos = [];

				this.evolucaoMensalEditaisPublicados?.push({
					id: 1,
					grupo: resultado[0].titulo,
					legenda: resultado[0].legenda,
					dados: resultado[0].dados
				});
				this.evolucaoMensalCandidatosInscritos?.push({
					id: 1,
					grupo: resultado[1].titulo,
					legenda: resultado[1].legenda,
					dados: resultado[1].dados
				});
			})
		);
	}

	private getOportunidadesPorMovimentacao() {
		this.subscriptions.push(
			this.dashboardService.dashboardOportunidadesPorTipoMovimentacao(this.orgaoUsuario).subscribe(async res => {
				this.oportunidadesPorMovimentacao = [
					{
						legenda: res.tooltip,
						dados: res.dados,
						total: res.dados.length > 0 ? res.dados.reduce((a: any, b: any) => a + b) : 0
					}
				];
			})
		);
	}

	private getUorgsComMaisEditaisPublicados() {
		this.subscriptions.push(
			this.dashboardService
				.dashboardEditaisPublicadosPorUorg(this.orgaoUsuario!, this.paginaUorgsComMaisCandidatosInscritos, this.ITENS_POR_PAGINA)
				.subscribe(async res => {
					this.uorgsComMaisEditaisPublicados = [
						{
							legenda: res.legenda,
							dados: res.dados,
							tooltip: res.tooltip,
							total: res.qtdUorgs
						}
					];
				})
		);
	}

	private getUorgsComMaisCandidatosInscritos() {
		this.subscriptions.push(
			this.dashboardService
				.dashboardCandidatosInscritosPorUorg(this.orgaoUsuario!, this.paginaUorgsComMaisCandidatosInscritos, this.ITENS_POR_PAGINA)
				.subscribe(async res => {
					this.uorgsComMaisCandidatosInscritos = [
						{
							legenda: res.legenda,
							dados: res.dados,
							tooltip: res.tooltip,
							total: res.qtdUorgs
						}
					];
					if (res.dados.length > 0) {
						this.quantitativos.quantidadeCandidatosInscritos =
							res.dados.length > 0 ? res.dados.reduce((a: any, b: any) => a + b) : 0;
					} else {
						this.quantitativos.quantidadeCandidatosInscritos = '0';
					}
				})
		);
	}

	private getUorgsComMaisOportunidadesPublicadas() {
		this.subscriptions.push(
			this.dashboardService
				.dashboardOportunidadesPublicadasPorUorg(
					this.orgaoUsuario!,
					this.paginaUorgsComMaisCandidatosInscritos,
					this.ITENS_POR_PAGINA
				)
				.subscribe(async res => {
					this.uorgsComMaisOportunidadesPublicadas = [
						{
							legenda: res.legenda,
							dados: res.dados,
							tooltip: res.tooltip,
							total: res.qtdUorgs
						}
					];
				})
		);
	}
}
