/* eslint-disable @typescript-eslint/no-unused-vars */
import { AfterViewInit, Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';

@Component({
	selector: 'sgp-card-grafico-bar-horizontal',
	templateUrl: './card-grafico-bar-horizontal.component.html'
})
export class CardGraficoBarHorizontalComponent implements AfterViewInit, OnChanges {
	ITENS_POR_PAGINA = 10;

	@Input() titulo!: string;
	@Input() dados?: any[];
	@Input() pagina!: number;
	@Output() anterior: EventEmitter<number> = new EventEmitter<number>();
	@Output() proximo: EventEmitter<number> = new EventEmitter<number>();
	colors = ['#2C608A'];
	data: any;
	options: any;
	legendas?: string[];
	tooltips: string[] = [];
	valores?: number[];
	totalResultado = 0;

	constructor() {
		this.data = undefined;
		this.dados = [];
		this.options = undefined;
	}
	ngAfterViewInit() {
		this.obterDados();
	}

	ngOnChanges() {
		this.obterDados();
	}

	obterDados() {
		if (this.dados) {
			for (const dado of this.dados) {
				this.legendas = dado.legenda;
				this.valores = dado.dados;
				this.totalResultado = dado.total;
				this.tooltips = dado.tooltip;
			}
		}

		this.data = {
			labels: this.legendas,
			datasets: [
				{
					label: 'Quantidade',
					data: this.valores,
					backgroundColor: this.colors,
					hoverBackgroundColor: this.colors
				}
			]
		};

		if (this.tooltips && this.tooltips.length > 0) {
			this.obterOpcoes(this.tooltips);
		} else {
			this.obterOpcoes(this.legendas!);
		}
	}

	obterOpcoes(tooltips: string[]) {
		this.options = {
			indexAxis: 'y',
			plugins: {
				legend: {
					display: false,
					labels: {
						color: '#495057'
					}
				},
				tooltip: {
					callbacks: {
						title: function (tooltipItem: any[], data: any) {
							const indice = tooltipItem[0].dataIndex;
							return tooltips[indice];
						}
					}
				}
			},
			scales: {
				x: {
					ticks: {
						color: '#495057'
					},
					grid: {
						color: '#ebedef'
					}
				},
				y: {
					ticks: {
						color: '#495057'
					},
					grid: {
						color: '#ebedef'
					}
				}
			}
		};
	}

	voltar() {
		this.anterior.emit(this.pagina - 1);
	}

	avancar() {
		this.proximo.emit(this.pagina + 1);
	}
}
