<div class="br-list" role="list" *ngFor="let edital of quantidade; let last = last">
	<div class="align-items-center br-item py-4" role="listitem">
		<div class="row align-items-center">
			<div class="col">
				<p-skeleton width="8rem" height="1.5rem"></p-skeleton>
			</div>
			<div class="col-auto">
				<p-skeleton width="8rem" height="2rem" borderRadius="4px"></p-skeleton>
			</div>
		</div>
	</div>
	<span class="br-divider" *ngIf="!last"></span>
</div>
